import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import Clients from '../components/sections/Clients';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroSplit imageFill className="illustration-section-01" />
        {/* <Clients topDivider bottomDivider /> */}
        {/* <FeaturesSplit invertMobile imageFill className="illustration-section-03" /> */}
        {/* <FeaturesTiles className="gradient-section" /> */}
        {/* <Pricing hasBgColor topDivider pricingSwitcher /> */}
        {/* <Testimonial hasBgColor className="gradient-section illustration-section-05" /> */}
        {/* <Cta hasBgColor invertColor split className="illustration-element-08" /> */}
      </React.Fragment>
    );
  }
}

export default Home;