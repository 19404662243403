import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Testimonial extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap illustration-section-03',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Testimonials',
      paragraph: 'Hear what our clients have to say.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-top" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/imaginefun.png')}
                        alt="Imagine Fun"
                        width={50}
                        height={50} />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      Vortia have been amazing to work with, we migrated from another provider after experiencing repeated incidents which are now a thing of the past.
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 pt-24 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Camden Cove</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a target="_blank" href="https://imaginefun.net">ImagineFun</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-top" data-reveal-delay="400">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/opcraft.png')}
                        alt="OPCraft"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      Vortia has been a breath of fresh air to work with, their fair pricing and stable network are what every other hosting company should be shooting for. Support is very accessible and the staff are also fun people.
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 pt-24 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Nick Smith</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a target="_blank" href="https://opcraft.co">OPCraft</a>
                    </span>
                  </div>
                </div>
              </div>

              {/* <div className="tiles-item reveal-from-top" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/testimonial-image-03.jpg')}
                        alt="Testimonial 03"
                        width={56}
                        height={56} />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id.
                      </p>
                  </div>
                  <div className="testimonial-item-footer text-xs mt-32 mb-0 pt-24 has-top-divider">
                    <span className="testimonial-item-name text-color-high">Jessie Kovalev</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link">
                      <a href="#0">AppName</a>
                    </span>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
